<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('MissedCallTemplatesGroupCreate')"
    @edit="(id) => onEdit('MissedCallTemplatesGroupEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'MissedCallTemplatesGroups',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'MissedCallTemplatesGroups',
      tableCaption: 'Группы шаблонов ответов для пропущенных звонков',
      breadcrumbs: [
        {
          text: 'Колл-центр',
          route: { name: 'CallCenterMain' },
        },
        {
          text: 'Группы шаблонов ответов для пропущенных звонков',
        },
      ],
      tableHeaders: [
        { text: 'Название группы', alias: 'name', order: 'name' },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
